<h3 class="title">{{ "Admin.Home.Rates" | translate }}</h3>
<div *ngFor="let rate of rates">
  <div
    *ngIf="rate.symbol != symbols.Usdt && rate.symbol != symbols.PolygonUsdt"
    class="flex flex-col space-y-3 py-5"
  >
    <div class="box">
      <p class="text-sm font-medium">
        {{ "Admin.Home.Rate" | translate }} {{ getCurrencyName(rate.symbol) }}:
      </p>
      <div class="mt-1 flex items-center gap-2">
        <span class="text-sm font-bold">1 {{ getCurrencyName(rate.symbol) }}:</span>
        <span>{{ rate.usd }}</span>
        <span class="text-sm font-bold">USD</span>
        <button class="ml-auto w-6" (click)="handleOpenModal(rate.symbol)" [disabled]="isUserObserverAdmin">
          <img src="assets/icons/edit.svg" alt="Edit" />
        </button>
      </div>
    </div>
  </div>
</div>

<jw-modal [id]="ModalConstants.AdminEditRate" (onClose)="handleCloseModal()">
  <div class="flex flex-col space-y-4" *ngIf="selectedRate">
    <h4 class="title-big">{{ "Admin.Home.Rate" | translate }} {{ getCurrencyName(selectedRate.symbol) }}</h4>
    <app-input
      type="number"
      [placeholder]="selectedRate.usd.toString()"
      [(ngModel)]="selectedRate.usd"
    ></app-input>
    <app-switch
      [(ngModel)]="selectedRate.autoupdates"
      [caption]="'Admin.Home.Autoupdates_rate' | translate"
    ></app-switch>
    <button type="submit" class="btn" (click)="updateRate(selectedRate)" [disabled]="isPending">
      {{ "Admin.Home.Change" | translate }}
    </button>
  </div>
</jw-modal>
