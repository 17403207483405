import { AfterViewInit, Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MyIdQrPayloadDto } from "src/app/common/DTO/kyc/my-id-qr-payload.dto";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { TelegramToMyIdRedirectPayload } from "src/app/common/interfaces/telegram-to-my-id-redirect-payload";
import { TelegramMiniAppHelper } from "src/app/common/utils/telegram-mini-app-helper.util";
import { EnvService } from "src/app/services/env.service";
import { KycService } from "src/app/services/kyc.service";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Component({
  selector: "app-myid-modal",
  templateUrl: "./myid-modal.component.html",
  styleUrls: ["./myid-modal.component.css"],
})
export class MyidModalComponent implements AfterViewInit {
  public qrBase64 = "";
  private _qrPayload: MyIdQrPayloadDto | null = null;
  public myIdLink = "";
  public isMiniApp = false;

  constructor(
    private _kycService: KycService,
    private _activeModal: NgbActiveModal,
    private _envService: EnvService,
    private readonly _localStorageService: LocalStorageService
  ) {}

  public async ngAfterViewInit(): Promise<void> {
    const res = await this._kycService.buildMyIdQrPayload();
    if (res.withError) {
      return;
    }
    this._qrPayload = res.params;
    this.myIdLink = await this.generateLink();
    this.isMiniApp = TelegramMiniAppHelper.isMiniApp();
  }

  private async generateLink(): Promise<string> {
    if (this._qrPayload == null) {
      return "";
    }

    if (TelegramMiniAppHelper.isMiniApp()) {
      await this._localStorageService.resetIsWebVerification();
    } else {
      await this._localStorageService.setIsWebVerification(true);
    }

    const url = this._envService.isProduction
      ? "https://myid.uz/api/v1/oauth2/authorization"
      : "https://devmyid.uz/api/v1/oauth2/authorization";

    const params = new URLSearchParams({
      client_id: this._qrPayload.clientId,
      method: this._qrPayload.verificationMethod,
      response_type: "code",
      scope: "address,contacts,doc_data,common_data",
      state: this._qrPayload.securityStamp,
      redirect_uri: `${this._envService.domain}${RouteConstants.myIdRedirect}?mode=telegramMiniApp`,
    });

    const link = `${url}?${params.toString()}`;

    return link;
  }

  public async handleRedirectFromTelegramMiniApp() {
    if (!this.isMiniApp) {
      return;
    }

    const accessToken = await this._localStorageService.accessToken();
    const accessTokenLifetime = await this._localStorageService.accessTokenLifetime();
    const refreshToken = await this._localStorageService.refreshToken();
    const refreshTokenLifetime = await this._localStorageService.refreshTokenLifetime();

    const payload: TelegramToMyIdRedirectPayload = {
      accessToken: String(accessToken),
      accessTokenLifetime: accessTokenLifetime?.toISOString(),
      refreshToken: String(refreshToken),
      refreshTokenLifetime: refreshTokenLifetime?.toISOString(),
      myIdUrl: this.myIdLink,
    };

    const encodedPayload = window.btoa(JSON.stringify(payload));

    TelegramMiniAppHelper.openLink(
      `${this._envService.domain}${RouteConstants.tmaToMyIdRedirect}?payload=${encodedPayload}`
    );
  }

  onClose() {
    this._activeModal.close();
  }
}
