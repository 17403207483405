import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "mfeCustom",
})
export class MfeCustomPipe implements PipeTransform {
  transform(value: string | number | undefined, moreDecimals: boolean = false): string {
    if (value === undefined) {
      return "0";
    }

    const roundTo = moreDecimals ? 5 : 2;

    const rounded = Number(value).toFixed(roundTo);
    return +value > 0 ? Number(rounded).toLocaleString("mfe") : "0";
  }
}
