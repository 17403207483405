import { Injectable } from "@angular/core";
import { TonConnectUI } from "@tonconnect/ui";
import { environment } from "src/environments/environment";
import { EnvService } from "./env.service";

namespace TonConnectUiSingleton {
  const tonConnectUi = new TonConnectUI({
    manifestUrl: `${environment.domain}/tonconnect-manifest.json`,
    actionsConfiguration: {
      twaReturnUrl: environment.tmaUrl,
    },
  });

  export function getInstance() {
    return tonConnectUi;
  }
}

@Injectable({
  providedIn: "root",
})
export class TonConnectService {
  tonConnectUi = TonConnectUiSingleton.getInstance();

  constructor(private readonly envService: EnvService) {}

  get onStatusChange() {
    return this.tonConnectUi?.onStatusChange;
  }

  get sendTransaction() {
    return this.tonConnectUi?.sendTransaction;
  }

  openTelegramWallet() {
    if (!this.tonConnectUi?.connected) {
      if (this.envService.isProduction) {
        this.tonConnectUi?.openSingleWalletModal("telegram-wallet");
      } else {
        this.tonConnectUi?.openModal();
      }
    } else {
      console.log(this.tonConnectUi?.account);
    }
  }
}
