import { Component, OnInit } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { KycService } from "src/app/services/kyc.service";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { EnvService } from "src/app/services/env.service";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Component({
  template: "",
})
export class MyidRedirectComponent implements OnInit {
  constructor(
    private _router: Router,
    private _kycService: KycService,
    private _envService: EnvService,
    private _localStorageService: LocalStorageService
  ) {}

  async ngOnInit(): Promise<void> {
    const currentRoute: ActivatedRouteSnapshot = this._router.routerState.snapshot.root;

    const code = currentRoute.queryParams["code"];
    const state = currentRoute.queryParams["state"];

    const result = await this._kycService.verifyKyc(code, state);

    const isWebVerification = await this._localStorageService.checkIfWebVerification();
    await this._localStorageService.resetIsWebVerification();

    if (isWebVerification) {
      this._router.navigateByUrl(`${RouteConstants.kycVerification}?isFail=${result}`);
    } else {
      const startAppParam = window.btoa(`${RouteConstants.kycVerification}?isFail=${result}`);
      window.open(`${this._envService.tmaUrl}?startapp=${startAppParam}`, "_self");
    }
  }
}
