import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[appPositiveDecimals]",
})
export class PositiveDecimalsDirective {
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,3}$/g);

  private specialKeys: Array<string> = [
    "Backspace",
    "Tab",
    "End",
    "Home",
    "ArrowLeft",
    "ArrowRight",
    "Del",
    "Delete",
  ];

  constructor(private el: ElementRef) {}

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    const input = event.target as HTMLInputElement;
    const value = input.value + event.key;

    if (value && !String(value).match(this.regex)) {
      event.preventDefault();
    }
  }
}
