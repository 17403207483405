export class TelegramMiniAppHelper {
  static isMiniApp() {
    try {
      return Boolean((window as any)?.Telegram?.WebApp?.initData);
    } catch {
      return false;
    }
  }

  static getStartAppParam() {
    try {
      return (window as any)?.Telegram?.WebApp?.initDataUnsafe?.start_param ?? null;
    } catch {
      return null;
    }
  }

  static openLink(url: string) {
    try {
      return (window as any)?.Telegram?.WebApp?.openLink?.(url);
    } catch (error) {
      console.error("open external link TMA error:", error);
    }
  }
}
