import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { HttpResultDto } from "src/app/common/DTO/http-result.dto";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { EnvService } from "src/app/services/env.service";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { firstValueFrom } from "rxjs";
import { ApiResponseDto } from "src/app/common/DTO/api-response.dto";
import { LimitPanelErrorCode } from "../common/enums/limit-panel-error-code.enum";
import { ErrorParserUtil } from "../common/utils/error-parser.util";
import { Constants } from "../common/constants/constants";
import { RatesPanelErrorCode } from "../common/enums/rates-panel-error-code.enum";

@Injectable({
  providedIn: "root",
})
export class RatesService {
  private readonly _baseReqOpts: { headers: HttpHeaders };

  constructor(
    private readonly _http: HttpClient,
    private readonly _env: EnvService
  ) {
    this._baseReqOpts = {
      headers: new HttpHeaders(Constants.JsonContentTypeHeader),
    };
  }

  public async getRates(): Promise<HttpResultDto<null, RateDto[]>> {
    const uri = `${this._env.serverUrl}${UrnConstants.GetRates}`;
    const result = new HttpResultDto<null, RateDto[]>(false);
    try {
      const res = (await firstValueFrom(this._http.get(uri))) as ApiResponseDto<RateDto[]>;
      result.params = res.data;
    } catch (e) {
      result.withError = true;
    }

    return result;
  }

  public async updateRate(rate: RateDto) {
    const uri = `${this._env.serverUrl}${UrnConstants.UpdateRate}`;
    const result = new HttpResultDto<RatesPanelErrorCode, null>(false);

    try {
      await firstValueFrom(this._http.put(uri, { rate: rate }, this._baseReqOpts));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  private getErrorCode(error: string): RatesPanelErrorCode {
    switch (error) {
      case Constants.InternalServerError:
        return RatesPanelErrorCode.InternalError;
      case Constants.Unauthorized:
        return RatesPanelErrorCode.Unauthorized;
      default:
        return RatesPanelErrorCode.InternalError;
    }
  }
}
