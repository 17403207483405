import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { WalletBalanceDto } from "src/app/common/DTO/wallets/wallet-balance.dto";
import { ConvertCurrencyHelper } from "src/app/common/utils/convert-currency-helper.util";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";

@Component({
  selector: "app-balance-card",
  templateUrl: "./balance-card.component.html",
  styleUrls: ["./balance-card.component.css"],
})
export class BalanceCardComponent {
  @Input() balanceItem: WalletBalanceDto = new WalletBalanceDto();
  @Input() rates: RateDto[] = [];
  @Input() isLoading: boolean = false;
  @Input() isShort: boolean = false;
  @Input() withSelect: boolean = false;
  @Input() selected: boolean = false;
  @Input() showRate: boolean = false;

  @Output() onSelect = new EventEmitter<WalletBalanceDto>();

  public CryptoSymbol = CryptoSymbol;

  constructor() {}

  getCurrencyCaption(): string {
    switch (this.balanceItem.currency) {
      case CryptoSymbol.Usdt:
      case CryptoSymbol.PolygonUsdt:
      case CryptoSymbol.TonUsdt:
        return "USDT";
      case CryptoSymbol.Trx:
        return "Tron";
      case CryptoSymbol.Matic:
        return "Polygon";
      case CryptoSymbol.Ton:
        return "Ton";
      case CryptoSymbol.Not:
        return "Not";
      case CryptoSymbol.Bitcoin:
        return "Bitcoin";
      default:
        return "-";
    }
  }

  getCurrencyName(): string {
    switch (this.balanceItem.currency) {
      case CryptoSymbol.Usdt:
      case CryptoSymbol.PolygonUsdt:
      case CryptoSymbol.TonUsdt:
        return "USDT";
      case CryptoSymbol.Trx:
        return "TRX";
      case CryptoSymbol.Matic:
        return "MATIC";
      case CryptoSymbol.Ton:
        return "TON";
      case CryptoSymbol.Not:
        return "NOT";
      case CryptoSymbol.Bitcoin:
        return "BTC";
      default:
        return "-";
    }
  }

  getNetworkName(): string {
    switch (this.balanceItem.currency) {
      case CryptoSymbol.Usdt:
      case CryptoSymbol.Trx:
        return "Tron";
      case CryptoSymbol.Matic:
      case CryptoSymbol.PolygonUsdt:
        return "Polygon";
      case CryptoSymbol.Ton:
      case CryptoSymbol.Not:
      case CryptoSymbol.TonUsdt:
        return "Ton";
      case CryptoSymbol.Bitcoin:
        return "Bitcoin";
      default:
        return "";
    }
  }

  onClickSelect() {
    this.onSelect.emit(this.balanceItem);
  }

  public getUzsValue(amount: number): number {
    if (!this.rates || this.rates.length === 0) {
      return 0;
    }

    let calculated = 0;
    calculated = ConvertCurrencyHelper.convertToUzs(amount, this.balanceItem.currency, this.rates);
    return calculated;
  }
}
